<template>
  <AccountContainer>
    <div>
      <router-view :key="$route.fullPath" />
    </div>
  </AccountContainer>
</template>

<script>
// The wrapper is used with the old selfservice menu. This component will become obsolete when the new menu and routes is ready for production.

import AccountContainer from '@containers/AccountContainer';

export default {
  components: {
    AccountContainer,
  },
};
</script>
